function dataCSVStringtify (headText, data, headers = []) {
  var csvContent = ''
  var dataString = ''
  csvContent += headText + '\n'
  // Add header
  // id: head -> match
  if (headers.length !== 0) {
    headers.forEach((header, index) => {
      csvContent +=
        index + 1 < headers.length
          ? Object.values(header)[0] + ','
          : Object.values(header)[0]
    })
    csvContent += '\n'
  } else csvContent += Object.keys(data[0]).join(',') + '\n'

  // stringtify array object data
  data.forEach(function (obj, index) {
    if (headers.length !== 0) {
      var tempArray = []
      // if have header match key
      headers.forEach(headerObj => {
        const key = headerObj.value
        var cell = obj[key]
        if (cell) tempArray.push(cell)
        else tempArray.push('')
      })
      dataString = tempArray.join(',')
    } else {
      dataString = Object.values(obj).join(',')
    }
    csvContent += data.length === index ? dataString : dataString + '\n'
  })

  return csvContent
}

export { dataCSVStringtify }
