<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
    class="pa-0"
  >
    <validation-observer v-slot="{ handleSubmit }">
      <v-form
        ref="form"
        lazy-validation
        @submit.prevent="handleSubmit(loadTotalTimeSheet)"
      >
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="5">
                <v-row>
                  <v-col
                    class="align-center d-flex mb-6"
                    cols="2"
                  >
                    <div>
                      <h3>{{ $t("messages.factoryName") }}</h3>
                    </div>
                  </v-col>
                  <v-col cols="10">
                    <v-row>
                      <v-col>
                        <validation-provider
                          v-slot="{ errors }"
                          name="customer"
                          rules="required"
                        >
                          <v-autocomplete
                            v-model="selectedCustomer"
                            :items="availableCustomers"
                            :no-data-text="$t('messages.noJobsSelect')"
                            :item-text="
                              (item) =>
                                item.code == null
                                  ? item.name
                                  : item.code + ' - ' + item.name
                            "
                            item-value="id"
                            clearable
                            dense
                            single-line
                            outlined
                            :error-messages="errors[0]"
                          />
                        </validation-provider>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="5">
                <v-row>
                  <v-col
                    class="align-center d-flex mb-6"
                    cols="2"
                  >
                    <div>
                      <h3>{{ $t("messages.date_range") }}</h3>
                    </div>
                  </v-col>
                  <!-- Single Date option selected -->
                  <v-col cols="10">
                    <v-row>
                      <v-col>
                        <v-menu
                          ref="menu"
                          v-model="menu"
                          :close-on-content-click="false"
                          :return-value.sync="selectedMonth"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="selectedMonth"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            />
                          </template>
                          <v-date-picker
                            v-model="selectedMonth"
                            type="month"
                            :max="maxMonth"
                            no-title
                            locale="ja"
                            scrollable
                          >
                            <v-spacer />
                            <v-btn
                              text
                              color="primary"
                              @click="menu = false"
                            >
                              {{ $t('messages.cancel') }}
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.menu.save(selectedMonth)"
                            >
                              {{ $t('messages.ok') }}
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="2">
                <v-row class="d-flex justify-end">
                  <v-col
                    cols="3"
                    class="d-flex justify-end"
                  >
                    <div>
                      <v-btn
                        width="11rem"
                        color="accent"
                        type="submit"
                      >
                        <span class="pr-5 pl-5 font-weight-bold text-h5">{{
                          $t("table.messages.search")
                        }}</span>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row class="mt-6">
              <v-col>
                <v-data-table
                  :headers="tableHeaders"
                  :items="exportData"
                  :options="options"
                  class="elevation-1"
                  stle
                  :loading-text="$t('table.messages.loading')"
                  :loading="loading"
                  :footer-props="{
                    itemsPerPageOptions: [5, 10, 15, 30, 50],
                    showFirstLastPage: false,
                    'items-per-page-text': '',
                  }"
                >
                  <template slot="no-data">
                    {{ $t("table.messages.no_data") }}
                  </template>
                  <template
                    v-slot:[`footer.page-text`]=""
                    class="mr-0"
                  >
                    {{ $t("table.messages.page") }}
                    {{ options.page }}
                  </template>

                  <template v-slot:[`footer.prepend`] />
                </v-data-table>
              </v-col>
            </v-row>

            <v-divider inset />
          </v-card-text>
        </v-card>
        <v-row class="mt-4">
          <v-col class="d-flex justify-end">
            <v-btn
              class="pr-5 pl-5 font-weight-bold text-h5"
              color="accent"
              :loading="loading"
              :disabled="exportData.length == 0"
              @click="exportCSV()"
            >
              {{ $t("messages.exportCSVTimeSheet") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </validation-observer>
    <v-snackbar v-model="snackbar">
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t("messages.close") }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
  import { get } from 'vuex-pathify'
  import moment from 'moment'
  import _ from 'lodash'
  import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
  import { required, email, min } from 'vee-validate/dist/rules'
  import { dataCSVStringtify } from '../../util/exportCSV'
  import { convertFloatToTime } from '../../util/convertFloatToTime'

  export default {
    name: 'TimeKeepingExport',
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    data () {
      return {
        selectedCustomer: '',
        availableCustomers: [],
        selectedMonth: '',
        options: {},
        loading: false,
        tableHeaders: [],
        snackbarMessage: '',
        snackbar: false,
        exportData: [],
        exportHeaders: [],
        exportHeadText: '',
        menu: false,
        maxMonth: moment().format('YYYY-MM'),
      }
    },
    computed: {
      ...get('salary', [
        'listSalaryExport',
        'message',
        'status',
        'error',
      ]),
      customerList: get('customer@list'),
    },
    watch: {
      status (value) {
        this.loading = value === 'loading'
      },
      listSalaryExport (value) {
        const formatedData = this.formatTableData(value)
        this.exportData = [...formatedData]

        this.updateTableHeader(value)
      },
      customerList (value) {
        this.availableCustomers = value.data.customers.map((o) => {
          return { name: o.name, code: o.code, id: o.id }
        })
      },
    },
    created () {
      // init call
      this.$store.dispatch('customer/getCustomers')
    },
    mounted () {
      // rule
      extend('month', {
        validate (value) {
          return value < 9 && value > 0 && !!value && !!value
        },
        message: this.$t('validator.invalid_month'),
      })
      extend('required', {
        ...required,
        message: this.$t('validator.required'),
      })
      // add head text for the file

      this.exportHeadText = '給与支給控除勤怠一覧表,'
    },
    methods: {
      loadTotalTimeSheet () {
        const payload = {
          month: moment(this.selectedMonth).format('MM/YYYY'),
          customerId: this.selectedCustomer,
        }
        this.exportData = []
        this.exportHeaders = []
        this.tableHeaders = [
          {
            text: this.$t('table.headers.manageId'),
            align: 'start',
            value: 'manageId',
            class: 'font-weight-bold',
            width: '9rem',
          },
          {
            text: this.$t('table.headers.partName'),
            align: 'start',
            value: 'name',
            class: 'font-weight-bold',
            width: '9rem',
          },
          {
            text: this.$t('table.headers.dayOfworks'),
            align: 'start',
            value: 'totalWorkday',
            class: 'font-weight-bold',
            width: '9rem',
          },
          {
            text: this.$t('table.headers.actualworkHours'),
            align: 'start',
            value: 'totalNormalWork',
            class: 'font-weight-bold',
            width: '9rem',
          },
          {
            text: this.$t('table.headers.nightWorkHours'),
            align: 'start',
            value: 'totalNightWork',
            class: 'font-weight-bold',
            width: '9rem',
          },
          {
            text: this.$t('table.headers.OTWorkHour'),
            align: 'start',
            value: 'totalOtWork',
            class: 'font-weight-bold',
            width: '9rem',
          },
        ]
        this.$store.dispatch('salary/getSalaryExport', payload)
      },
      formatTableData (data) {
        const tempData = data
        const headers = [
          'totalWorkHour',
          'totalNightWork',
          'totalNormalWork',
          'totalOtWork',
          'totalNightWorkSalary',
          'totalNormalWorkSalary',
          'totalOtWorkSalary',
        ]
        const divisionsHeaders = [
          'totalWorkHour',
          'totalNightWork',
          'totalNormalWork',
          'totalOtWork',
          'totalNormalWorkSalary',
          'totalNightWorkSalary',
          'totalOtWorkSalary',
          'totalWorkSalary',
        ]
        this.exportHeaders = [
          { manageId: 'manageId' },
          { name: '項目' },
          { totalWorkday: '出勤日数' },
          { totalWorkHour: '実働時間' },
          { totalNightWork: '夜勤' },
          { totalOtWork: '実働（残業)' },
          { six_hour: '6時' },
          { overtime: '熊本（残業）' },
          { tax_commuting_fee: '非課税通勤費' },
          { totalNormalWorkSalary: '出勤日数' },
          { totalNightWorkSalary: '夜勤' },
          { totalOtWorkSalary: '実働（残業)' },
          { allowance: '手当' },
          { stool: '検便' },
          { idDeposit: 'IDデポジット' },
          { lockerKeyFee: 'ﾛｯｶｰ鍵代' },
          { cleanFee: 'ｸﾘｰﾆﾝｸﾞ代' },
          { mealBill: '食事代' },
          { fix_point: '修正分' },
          { refund: '現金支給済み' },
          { total_tax: '課税支給合計' },
          { total_non_tax: '非税支給合計' },
          { total_payment: '支給合計' },
          { total_covered_insurance: '労保対象合計' },
          { convered_company_insurance: '社保対象金銭' },
          { total_fixed_wages: '固定賃金合計' },
          { correction: '訂正' },
          { work_clothes: '作業服代' },
          { income_tax: '所得税' },
          { deduction_tax: '控除合計' },
          { after_deduction_company: '社保控除後計' },
          { total_deduction: '差引支給合計' },
          { total_payment_cash: '現金支給額' },
          { total_payment_transfer: '振込支給合計' },
          { transfer_payment_1: '振込支給１' },
          { comulative_tax: '課税支給累計' },
          { comulative_insurance: '社会保険累計' },
          { comulative_income_tax: '所得税累計' },
        ]
        const dataUpdate = {
          six_hour: '0:00',
          overtime: '0:00',
          tax_commuting_fee: '0',
          allowance: '0',
          stool: '0',
          idDeposit: '0',
          lockerKeyFee: '0',
          cleanFee: '0',
          mealBill: '0',
          fix_point: '0',
          refund: '0',
          total_tax: '0',
          total_non_tax: '0',
          total_payment: '0',
          total_covered_insurance: '0',
          convered_company_insurance: '0',
          total_fixed_wages: '0',
          correction: '0',
          work_clothes: '0',
          income_tax: '0',
          deduction_tax: '0',
          after_deduction_company: '0',
          total_deduction: '0',
          total_payment_cash: '0',
          total_payment_transfer: '0',
          transfer_payment_1: '0',
          comulative_tax: '0',
          comulative_insurance: '0',
          comulative_income_tax: '0',
        }
        const jobs = []
        const payload = []

        // ----------------------------------------------------
        // get all avaliable jobs
        tempData.forEach((el) => {
          el.jobs.forEach((division) => {
            if (!jobs.find((o) => o.jobId === division.jobId)) {
              jobs.push(division)
            }
          })
        })
        // forms new exportHeader
        jobs.forEach((division) => {
          divisionsHeaders.forEach((divisionsHeader) => {
            const exportHeader = {}
            const headerKey = division.jobId + '_' + divisionsHeader
            const headerValue =
              division.name + this.$t('table.headers.' + divisionsHeader)
            exportHeader[headerKey] = headerValue

            if (!this.exportHeaders[headerKey]) {
              this.exportHeaders.push(exportHeader)
            }
          })
        })
        // froms new data set
        tempData.forEach((el) => {
          const data = { ...el }
          Object.entries(dataUpdate).forEach(([key, value]) => { data[key] = value })
          // delete not include
          delete data.jobs
          // check if total data is null
          divisionsHeaders.forEach((divisionsHeader) => {
            data[divisionsHeader] = data[divisionsHeader]
              ? data[divisionsHeader]
              : '0'
          })
          // set up jobs data
          jobs.forEach((division) => {
            divisionsHeaders.forEach((divisionsHeader) => {
              const dataByHeader = el.jobs.find(
                (o) => o.jobId === division.jobId,
              )

              data[division.jobId + '_' + divisionsHeader] = dataByHeader
                ? dataByHeader[divisionsHeader] || '0'
                : '0'
            })
          })
          payload.push(data)
        })
        return payload
      },
      updateTableHeader (data) {
        data.forEach((parttimerAttendence) => {
          const headerUpdate = [
            'allowance',
            'stool',
            'idDeposit',
            'lockerKeyFee',
            'cleanFee',
            'mealBill',
            'fix_point',
            'refund',
            'total_tax',
            'total_non_tax',
            'total_payment',
            'total_covered_insurance',
            'convered_company_insurance',
            'total_fixed_wages',
            'correction',
            'work_clothes',
            'income_tax',
            'deduction_tax',
            'after_deduction_company',
            'total_deduction',
            'total_payment_cash',
            'total_payment_transfer',
            'transfer_payment_1',
            'comulative_tax',
            'comulative_insurance',
            'comulative_income_tax',
          ]
          const headersSalary = [
            'six_hour',
            'overtime',
            'tax_commuting_fee',
            'totalNormalWorkSalary',
            'totalNightWorkSalary',
            'totalOtWorkSalary',
          ]
          parttimerAttendence.jobs.forEach((division, index) => {
            const headersJob = [
              'totalNormalWork',
              'totalNightWork',
              'totalOtWork',
            ]
            headersJob.forEach((header) => {
              const tableHeader = {
                text: division.name + this.$t('table.headers.' + header),
                align: 'start',
                value: division.jobId + '_' + header,
                class: 'font-weight-bold',
                width: '9rem',
              }
              if (!this.tableHeaders.find((o) => o.value === tableHeader.value)) {
                this.tableHeaders.push(tableHeader)
              }
            })
          })
          headersSalary.forEach((header) => {
            const tableHeader = {
              text: this.$t('table.headers.' + header),
              align: 'start',
              value: header,
              class: 'font-weight-bold',
              width: '9rem',
            }
            if (!this.tableHeaders.find((o) => o.value === tableHeader.value)) {
              this.tableHeaders.push(tableHeader)
            }
          })
          parttimerAttendence.jobs.forEach((division, index) => {
            const headersJob = [
              'totalNormalWorkSalary',
              'totalNightWorkSalary',
              'totalOtWorkSalary',
            ]
            headersJob.forEach((header) => {
              const tableHeader = {
                text: division.name + this.$t('table.headers.' + header),
                align: 'start',
                value: division.jobId + '_' + header,
                class: 'font-weight-bold',
                width: '10rem',
              }
              if (!this.tableHeaders.find((o) => o.value === tableHeader.value)) {
                this.tableHeaders.push(tableHeader)
              }
            })
          })
          headerUpdate.forEach((header) => {
            const tableHeader = {
              text: this.$t('table.headers.' + header),
              align: 'start',
              value: header,
              class: 'font-weight-bold',
              width: '10rem',
            }
            if (!this.tableHeaders.find((o) => o.value === tableHeader.value)) {
              this.tableHeaders.push(tableHeader)
            }
          })
        })
      },
      exportCSV () {
        const divisionsHeaders = [
          'totalNightWork',
          'totalNormalWork',
          'totalOtWork',
        ]
        const customerName = this.availableCustomers.find(
          (o) => (o.id === this.selectedCustomer),
        ).name
        const currentDate = moment().format(this.$t('date_format.jafull'))
        const editDate = moment(this.selectedMonth + '-01').format(
          'YYYY年 MM月DD日',
        )
        const payDay = moment(this.selectedMonth + '-01').format(
          'YYYY年 MM月DD日',
        )
        // set header

        // exmaple
        // 給与支給控除勤怠一覧表,株式会社テンポイント,2021年 07月 20日 作成
        // 2021年 7月度給与,支給日：2021年 6月30日,順序：コード順

        this.exportHeadText +=
          customerName +
          ',' +
          currentDate +
          '作成' +
          '\n' +
          editDate +
          '度給与' +
          ',' +
          '支給日：' +
          payDay +
          ',順序：コード順'

        const data = dataCSVStringtify(
          this.exportHeadText,
          this.exportData,
          this.tableHeaders,
        )
        var fileDownload = require('js-file-download')
        fileDownload(data, '給与インポート.txt')
        this.exportHeadText = '給与支給控除勤怠一覧表,'
      },
    },
  }
</script>
