var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"id":"dashboard-view","fluid":"","tag":"section"}},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.loadTotalTimeSheet)}}},[_c('v-card',[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('v-row',[_c('v-col',{staticClass:"align-center d-flex mb-6",attrs:{"cols":"2"}},[_c('div',[_c('h3',[_vm._v(_vm._s(_vm.$t("messages.factoryName")))])])]),_c('v-col',{attrs:{"cols":"10"}},[_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":"customer","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.availableCustomers,"no-data-text":_vm.$t('messages.noJobsSelect'),"item-text":function (item) { return item.code == null
                                ? item.name
                                : item.code + ' - ' + item.name; },"item-value":"id","clearable":"","dense":"","single-line":"","outlined":"","error-messages":errors[0]},model:{value:(_vm.selectedCustomer),callback:function ($$v) {_vm.selectedCustomer=$$v},expression:"selectedCustomer"}})]}}],null,true)})],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"5"}},[_c('v-row',[_c('v-col',{staticClass:"align-center d-flex mb-6",attrs:{"cols":"2"}},[_c('div',[_c('h3',[_vm._v(_vm._s(_vm.$t("messages.date_range")))])])]),_c('v-col',{attrs:{"cols":"10"}},[_c('v-row',[_c('v-col',[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.selectedMonth,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},on:{"update:returnValue":function($event){_vm.selectedMonth=$event},"update:return-value":function($event){_vm.selectedMonth=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.selectedMonth),callback:function ($$v) {_vm.selectedMonth=$$v},expression:"selectedMonth"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"type":"month","max":_vm.maxMonth,"no-title":"","locale":"ja","scrollable":""},model:{value:(_vm.selectedMonth),callback:function ($$v) {_vm.selectedMonth=$$v},expression:"selectedMonth"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" "+_vm._s(_vm.$t('messages.cancel'))+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.selectedMonth)}}},[_vm._v(" "+_vm._s(_vm.$t('messages.ok'))+" ")])],1)],1)],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-row',{staticClass:"d-flex justify-end"},[_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"3"}},[_c('div',[_c('v-btn',{attrs:{"width":"11rem","color":"accent","type":"submit"}},[_c('span',{staticClass:"pr-5 pl-5 font-weight-bold text-h5"},[_vm._v(_vm._s(_vm.$t("table.messages.search")))])])],1)])],1)],1)],1),_c('v-row',{staticClass:"mt-6"},[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.tableHeaders,"items":_vm.exportData,"options":_vm.options,"stle":"","loading-text":_vm.$t('table.messages.loading'),"loading":_vm.loading,"footer-props":{
                  itemsPerPageOptions: [5, 10, 15, 30, 50],
                  showFirstLastPage: false,
                  'items-per-page-text': '',
                }},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("table.messages.page"))+" "+_vm._s(_vm.options.page)+" ")]},proxy:true},{key:"footer.prepend",fn:function(){return undefined},proxy:true}],null,true)},[_c('template',{slot:"no-data"},[_vm._v(" "+_vm._s(_vm.$t("table.messages.no_data"))+" ")])],2)],1)],1),_c('v-divider',{attrs:{"inset":""}})],1)],1),_c('v-row',{staticClass:"mt-4"},[_c('v-col',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"pr-5 pl-5 font-weight-bold text-h5",attrs:{"color":"accent","loading":_vm.loading,"disabled":_vm.exportData.length == 0},on:{"click":function($event){return _vm.exportCSV()}}},[_vm._v(" "+_vm._s(_vm.$t("messages.exportCSVTimeSheet"))+" ")])],1)],1)],1)]}}])}),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function(ref){
                var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"pink","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" "+_vm._s(_vm.$t("messages.close"))+" ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarMessage)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }